import React, { Component } from "react";
import "../../static/css/speisekarte.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

import cover from "../../static/img/SpeiseKarte/cover.jpg";
import s0 from "../../static/img/SpeiseKarte/s0.jpg";
import p1 from "../../static/img/SpeiseKarte/winter_2024/p1.jpg";
import p2 from "../../static/img/SpeiseKarte/winter_2024/p2.jpg";
// import p5 from "../../static/img/SpeiseKarte/06_04_2024/p5.png";
// import p6 from "../../static/img/SpeiseKarte/06_04_2024/p6.png";
// import p7 from "../../static/img/SpeiseKarte/06_04_2024/p7.png";
// import p8 from "../../static/img/SpeiseKarte/06_04_2024/p8.png";
// import p9 from "../../static/img/SpeiseKarte/06_04_2024/p9.png";
// import p10 from "../../static/img/SpeiseKarte/06_04_2024/p10.png";
// import p11 from "../../static/img/SpeiseKarte/06_04_2024/p11.png";
// import p12 from "../../static/img/SpeiseKarte/06_04_2024/p12.png";
// import p13 from "../../static/img/SpeiseKarte/06_04_2024/p13.png";
// import p14 from "../../static/img/SpeiseKarte/06_04_2024/p14.png";
// import p15 from "../../static/img/SpeiseKarte/06_04_2024/p15.png";
// import p16 from "../../static/img/SpeiseKarte/06_04_2024/p16.png";


class Dauerkarte extends Component {

  render() {
    return (
      <div className="CarouselContainer" id="KartenCintainr">
        <div className="fixed" id="KartenCintainr">
          <Carousel data-bs-theme="dark" interval={99999999999}>
            <Carousel.Item>
              <img className="slidImg" src={cover} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={s0} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p1} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p2} alt="img" />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img className="slidImg" src={p5} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p6} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p7} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p8} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p9} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p10} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p11} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p12} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p13} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p14} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p15} alt="img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="slidImg" src={p16} alt="img" />
            </Carousel.Item> */}
          </Carousel>
        </div>

      </div>
    );
  }
}

export default Dauerkarte;
